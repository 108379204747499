export const COOKIE_PARAM_NAME = {
    SESSION_UUID: 'sniff_session_uuid',
    REDIRECT: 'redirect_uri',
    REDIRECT_ONBOARD: 'redirect_onboard_uri',
    SUBSCRIBE: 'subscribe',
    USER: 'user',
    SNIFF_APP_DOWNLOAD_BANNER: 'sniff_app_download_banner',
    SMARTBANNER_INSTALLED: 'smartbanner-installed',
    SMARTBANNER_CLOSED: 'smartbanner-closed',
    FILTERS: 'filters',
    SEARCH_FILTERS: 'search_filters',
    SEARCH_FILTERS_AVAILABILITY: 'search_filters_availability',
    SEARCH_FILTERS_LOCATION: 'search_filters_location',
    SIGN_UP_FROM: 'sniff_sign_up_from',
    VISIT_LIST_FILTERS: 'visit-list-filters',
    RESERVATION_LIST_FILTERS: 'reservation-list-filters',
    SPOT_DATA_FILTER: 'spot-data-filter',
    REDIRECT_GOOGLE_CAL: 'sniff_sync_google_redirect_uri',
    // Test
    HOME_TEST: 'sniff_home_test',
    HOME_TEST_MODAL: 'sniff_home_test_modal',
    BOOK_CAL: 'sniff_book_cal',
    HOME_APP_MODAL: 'sniff_home_app_modal',
    HOME_APP_TEST: 'sniff_home_app_test',
    BIG_SPOTS_TEST: 'sniff_big_spot_test',
    FASTER_HOST_LAND_TEST: 'sniff_faster_host_landing_test',
    SEARCH_FILTER_TEST: 'sniff_search_filter_test',
    HOST_MEMBER_BANNER: 'sniff_member_banner',
    SEO_CITY_TEST: 'sniff_seo_city_test',
    HOME_PAGE_TEST: 'sniff_home_banner_test',
    SEARCH_QUALITY_SCORE_TEST: 'sniff_search_quality_score_test',
    SEO_HOST_SIGNUP_TEST: 'sniff_seo_host_signup_test',
    SEO_HOME_SEARCH_PAGE_TEST: 'sniff_home_search_page_test',
    SEARCH_PAGE_TEST: 'sniff_search_page_test',
    SEO_HOME_PAGE_V2_TEST: 'sniff_home_page_v2_test',
    SEARCH_PAGE_AA_TEST: 'sniff_listing_aa_3225',
    HOST_LANDING_PAGE_TEST: 'sniff_host_landing_ab_3392',
    HOST_POST_PUBLISH_TEST: 'sniff_post_pub_ab_3395',
    SEO_HOME_PAGE_V3_TEST: 'sniff_home_page_v3_test',
    HOST_PHOTO_WARN_TEST: 'sniff_host_photo_warn_ab_3497',
    HOST_MIN_PHOTO_TEST: 'sniff_host_min_photo_ab_3550',
    HOST_SPOT_FLOW2_TEST: 'sniff_host_spot_flow_ab_3877',
    LANDING_SEARCH_PAGE: 'sniff_landing_search_page_3970',
    SNIFF_FIRST_CLICK: 'sniff_first_click_4028',
    SEARCH_SIGNUP_TEST: 'sniff_search_signup_ab_4102',
    HOST_SIGNUP_FLOW_TEST: 'sniff_host_signup_flow_ab_4474',
    HOST_SIGNUP_PHONE_TEST: 'sniff_host_signup_phone_ab_4140',
    HOST_SIGNUP_SOCIAL_TEST: 'sniff_host_signup_social_ab_4458',
    GUEST_SIGNUP_POPUP_TEST: 'sniff_guest_signup_popup_ab_4434',
    GUEST_AUTH_TEST: 'sniff_guest_auth_ab_4866',
    GUEST_CALENDAR_TEST: 'sniff_guest_calendar_ab_4546',
    ONE_PAGE_CHECKOUT_TEST: 'sniff_one_page_checkout_ab_4757',
    SNIFF_SEARCH_2024_TEST: 'sniff_search_2024_2',
    SPOT_DETAIL_4686_TEST: 'sniff_spot_detail_ab_4686',
    FIRST_SPOT_4686: 'first_spot_4686_2',
    SNIFF_SEO_POPUP_4936: 'sniff_seo_popup_4936',
    SNIFF_SEO_POPUP_SHOWN_4936: 'sniff_seo_popup_2_shown_4936',
    SNIFF_FILTER_AUTH_4954: 'sniff_filter_listings_test_4954',
    GUEST_EMAIL_ONLY_SIGNUP: 'sniff_guest_email_signup_4969',
    GUEST_HERO_SIGNUP: 'sniff_guest_hero_signup_4986',
    HOST_SPOT_DESCRIPTION: 'sniff_host_spot_description_5166',
    HOST_SPOT_INSTRUCTIONS: 'sniff_host_spot_instructions_5167',
    HOST_SPOT_DISTRACTIONS: 'sniff_host_spot_distractions_5173',
    HOST_SPOT_PHOTO_COPY_V3: 'sniff_host_spot_photo_copy_v3_5162',
    GUEST_PHOTO_CAROUSEL: 'sniff_guest_photo_carousel_5202',
    GUEST_SPOT_DETAIL_SCROLL: 'sniff_guest_spot_detail_scroll_5240',
    OFFER_MODAL_V3: 'sniff_offer_modal_v3',
    OFFER_MODAL_V3_SHOWN: 'sniff_offer_modal_v3_shown',
    FRONT_BOT_NAV: 'sniff_front_bot_nav_5335',
    GUEST_NO_MESSAGE_BTN_TEST: 'sniff_no_message_btn_5342',
    GUEST_NO_MESSAGE_BTN_NEW_TEST: 'sniff_no_message_btn_new_5342',
    OFFER_MODAL_V2: 'sniff_offer_modal_v2',
    OFFER_MODAL_V2_SHOWN: 'sniff_offer_modal_v2_shown',
    FRONT_POPUP_SPOT_ALERT: 'sniff_popup_spot_alert_v2_5290',
    FRONT_POPUP_SPOT_ALERT_SHOWN: 'sniff_popup_spot_alert_v2_shown',
    FRONT_NO_SNIFFPASS: 'sniff_no_sniffpass_5428',
    FRONT_BLUR_SIGNUP: 'sniff_blur_signup',
    FRONT_ADDITIONAL_FILTERS: 'sniff_front_additional_filters_5395',
    HOST_SPOT_EARNING_TITLE: 'sniff_host_spot_earning_title',
};
